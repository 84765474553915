import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import { Radio } from "antd";
import API, { COMMON, Ticket3 } from "../../network/api";
import "moment-timezone";
import ModalCommon from "../../components/Modal";
import { TEXT_DEF } from "../../constant/text";
import Wheel1 from "./Wheel1";
import Wheel2 from "./Wheel2";
import moment from "moment";

import { Form } from "antd";
const SettingSys = () => {
  const [form] = Form.useForm();

  const auth = JSON.parse(localStorage.getItem("auth"));
  const roleLocal = localStorage.getItem("roles");
  const roleArr = roleLocal?.split(",");

  const navigate = useNavigate();
  const [maintenance1, setMaintenance1] = useState({
    id: '',
    module: '',
    item: '',
    value: '',
    value1: '',
    value2: '',
  });
  const [coinUsed1, setCoinUsed1] = useState({
    id: '',
    module: '',
    item: '',
    value: '',
    value1: '',
    value2: '',
  });
  const [multiple1, setMultiple1] = useState({
    id: '',
    module: '',
    item: '',
    value: '',
    value1: '',
    value2: '',
  });
  const [prizeList1, setPrizeList1] = useState([])


  const [maintenance2, setMaintenance2] = useState({
    id: '',
    module: '',
    item: '',
    value: '',
    value1: '',
    value2: '',
  });
  const [coinUsed2, setCoinUsed2] = useState({
    id: '',
    module: '',
    item: '',
    value: '',
    value1: '',
    value2: '',
  });
  const [multiple2, setMultiple2] = useState({
    id: '',
    module: '',
    item: '',
    value: '',
    value1: '',
    value2: '',
  });
  const [prizeList2, setPrizeList2] = useState([])

  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);
  const [indexTab, setIndexTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [keyModal, setKeyModal] = useState();
  const [authConfig, setAuthConfig] = useState();


  const [time, setTime] = useState();
  const [totalDeposit, setTotalDeposit] = useState();

  useEffect(() => {
    if (indexTab == 0) {
      getDataWheel1();
    } else {
      getDataWheel2();
    }
  }, []);

  useEffect(() => {
    if (indexTab == 0) {
      getDataWheel1();
    } else {
      getDataWheel2();
    }
  }, [indexTab]);

  useEffect(() => {
    if (
      auth?.role === "supper" ||
      (auth?.role === "admin" && roleArr.some((el) => el === "wheel_1_sys_edit"))
    ) {
      return setIndexTab(0);
    } else if (
      auth?.role === "supper" ||
      (auth?.role === "admin" && roleArr.some((el) => el === "wheel_2_sys_edit"))
    ) {
      return setIndexTab(1);
    }
  }, []);

  const getDataWheel1 = async () => {
    try {
      setLoading(true)
      const res = await API.get(
        `${COMMON}/all?take=200&skip=1&order=DESC&keyword=%7B%22module%22%3A%22WHEEL1%22%7D`
      );
      const data = res?.data?.data?.result[0];

      if (data.length > 0) {
        const newArray = data.map((el) => {
          return {
            id: el.id,
            module: el.module,
            item: el.item,
            value: el.value,
            value1: el.value1,
            value2: el.value2,
          };
        });

        const dataNew = newArray.sort((a, b) => parseInt(a.value) - parseInt(b.value));

        setMaintenance1(dataNew.find((el) => el.item === "wheel_1_maintenance"))
        setCoinUsed1(dataNew.find((el) => el.item === "wheel_1_coin_used"))
        setMultiple1(dataNew.find((el) => el.item === "wheel_1_multiple"));
        setPrizeList1(dataNew.filter((el) => el.item === "wheel_1_reward"))
      }

      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err);
    }
  };

  const getDataWheel2 = async () => {
    try {
      setLoading(true)
      const res = await API.get(
        `${COMMON}/all?take=200&skip=1&order=DESC&keyword=%7B%22module%22%3A%22WHEEL2%22%7D`
      );
      const data = res?.data?.data?.result[0];
      if (data.length > 0) {
        const newArray = data.map((el) => {
          return {
            id: el.id,
            module: el.module,
            item: el.item,
            value: el.value,
            value1: el.value1,
            value2: el.value2,
          };
        });
        const dataNew = newArray.sort((a, b) => parseInt(a.value) - parseInt(b.value));

        setMaintenance2(dataNew.find((el) => el.item === "wheel_2_maintenance"))
        setCoinUsed2(dataNew.find((el) => el.item === "wheel_2_coin_used"))
        setMultiple2(dataNew.find((el) => el.item === "wheel_2_multiple"));
        setPrizeList2(dataNew.filter((el) => el.item === "wheel_2_reward"))
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err);
    }
  };

  const getSumRate = (list) => {
    let sum = 0;
    list?.forEach((e) => {
      sum = (Number(sum) + Number(e?.value2)).toFixed(3);
    });
    return +sum;
  };

  const removeDuplicateItem = (arr = []) => {
    const uniqueValue1Set = new Set();
    const filteredArray = arr.filter((item) => {
      if (!uniqueValue1Set.has(item.value1)) {
        uniqueValue1Set.add(item.value1);
        return true;
      }
      return false;
    });

    return filteredArray
  }

  const showModalConfirm = useCallback((e, key) => {
    e?.preventDefault();
    setIsShowModalConfirm(true);
    setKeyModal(key);
  }, []);

  const handleUpdate = async () => {
    try {
      let objUpdate = {}
      switch (keyModal) {
        case 'maintenance1':
          objUpdate = {
            id: maintenance1?.id,
            body: {
              value: maintenance1?.value
            }
          }
          break;
        case 'maintenance2':
          objUpdate = {
            id: maintenance2?.id,
            body: {
              value: maintenance2?.value
            }
          }
          break;

        case 'coinUsed1':
          objUpdate = {
            id: coinUsed1?.id,
            body: {
              value: coinUsed1?.value.toString()
            }
          }
          break;
        case 'coinUsed2':
          objUpdate = {
            id: coinUsed2?.id,
            body: {
              value: coinUsed2?.value.toString()
            }
          }
          break;

        case 'multiple1':
          objUpdate = {
            id: multiple1?.id,
            body: {
              value: multiple1?.value.toString()
            }
          }
          break;
        case 'multiple2':
          objUpdate = {
            id: multiple2?.id,
            body: {
              value: multiple2?.value.toString()
            }
          }
          break;

        case 'prize1':
          objUpdate = {
            body: {
              sysConfigs: prizeList1
            }
          }
          break;
        case 'prize2':
          objUpdate = {
            body: {
              sysConfigs: prizeList2
            }
          }
          break;
      }

      if (keyModal === 'prize1') {
        const sum = getSumRate(removeDuplicateItem(prizeList1))
        if (sum > 1) {
          alert('Tổng tỷ lệ không được lớn hơn 1 !')
          setIsShowModalConfirm(false)
          getDataWheel1()
          return;
        }
      }
      if (keyModal === 'prize2') {
        const sum = getSumRate(removeDuplicateItem(prizeList2))
        if (sum > 1) {
          alert('Tổng tỷ lệ không được lớn hơn 1 !')
          setIsShowModalConfirm(false)
          getDataWheel2()
          return;
        }
      }

      const res = await API.patch(`${COMMON}/${(keyModal === 'prize1' || keyModal === 'prize2') ? 'ids'
        : objUpdate?.id}`,
        objUpdate.body);
      if (res?.data?.data?.result) {
        setIsShowModalConfirm(false)
        setIsShowModalSuccess(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="col-12">
      <div
        className="col-12"
        style={{
          borderBottom: "1px solid #CCC",
          paddingBottom: "2rem",
          marginBottom: "2rem",
        }}
      >
        <Radio.Group
          value={indexTab}
          onChange={(e) => setIndexTab(e.target.value)}
        >
          {auth?.role === "supper" ||
            (auth?.role === "admin" && roleArr?.some(
              (el) => (el === "wheel_1_sys_view" || el === "wheel_1_sys_edit")
            )) ? (
            <Radio.Button value={0}>{TEXT_DEF.wheel_1}</Radio.Button>
          ) : null}
          {auth?.role === "supper" ||
            (auth?.role === "admin" && roleArr?.some(
              (el) => (el === "wheel_2_sys_view" || el === "wheel_2_sys_edit")
            )) ? (
            <Radio.Button value={1}>{TEXT_DEF.wheel_2}</Radio.Button>
          ) : null}
        </Radio.Group>
      </div>
      {indexTab === 0 && maintenance1 ? (
        <Wheel1
          maintenance1={maintenance1}
          setMaintenance1={setMaintenance1}
          coinUsed1={coinUsed1}
          setCoinUsed1={setCoinUsed1}
          multiple1={multiple1}
          setMultiple1={setMultiple1}
          prizeList1={prizeList1}
          setPrizeList1={setPrizeList1}
          // handleUpdate={handleUpdate}
          showModalConfirm={showModalConfirm}
          loading={loading}
        />
      ) : indexTab === 1 ? (
        <Wheel2
          maintenance2={maintenance2}
          setMaintenance2={setMaintenance2}
          coinUsed2={coinUsed2}
          setCoinUsed2={setCoinUsed2}
          multiple2={multiple2}
          setMultiple2={setMultiple2}
          prizeList2={prizeList2}
          setPrizeList2={setPrizeList2}
          // handleUpdate={handleUpdate}
          showModalConfirm={showModalConfirm}
          loading={loading}
        />
      ) : null}
      {isShowModalConfirm && (
        <ModalCommon
          isShow={isShowModalConfirm}
          message={TEXT_DEF.text0062}
          txtBtnAcc={TEXT_DEF.text0008}
          textBtnClose={TEXT_DEF.text0023}
          actionNext={handleUpdate}
          close={() => setIsShowModalConfirm(false)}
        />
      )}
      {isShowModalSuccess && (
        <ModalCommon
          isShow={isShowModalSuccess}
          message={TEXT_DEF.text0063}
          txtBtnAcc={TEXT_DEF.text0007}
          actionNext={() => {
            setIsShowModalSuccess(false);
            getDataWheel1()
            getDataWheel2()
          }}
          close={() => {
            setIsShowModalSuccess(false);
            getDataWheel1()
            getDataWheel2()
          }}
        />
      )}
    </div>
  );
};

export default SettingSys;
