export const LIST_CYCLE = {
  ticket_1_time_cycle: "Chu kỳ mở thưởng",
  tick_1_multiple: "Số vòng cược",
  tick_1_point_draw_n: "Số lần quay tỷ lệ Min",
  tick_1_checkin_to: "Phút kết thúc nhận vé",
  tick_1_checkin_from: "Phút bắt đầu nhận vé",
  tick_1_point_max: "Điểm thưởng Max",
  tick_1_point_min: "Điểm thưởng Min",
};

export const DAYS_WEEK = [
  {
    value: "1",
    label: "Thứ 2",
  },
  {
    value: "2",
    label: "Thứ 3",
  },
  {
    value: "3",
    label: "Thứ 4",
  },
  {
    value: "4",
    label: "Thứ 5",
  },
  {
    value: "5",
    label: "Thứ 6",
  },
  {
    value: "6",
    label: "Thứ 7",
  },
  {
    value: "0",
    label: "Chủ Nhật",
  },
];

export const SETTING_SYTEMS = [
  "tick_1_edit",
  "tick_2_edit",
  "tick_3_checkin_edit",
];

export const SETTING_HIS = [
  'wheel_1_his_view',
  'wheel_2_his_view'
  // "tick_1_view",
  // "tick_2_view",
  // "tick_3_checkin_view",
  // "tick_1_edit",
  // "tick_2_edit",
  // "tick_3_checkin_edit",
  // "tick_3_award_view",
  // "tick_3_award_edit",
];

export const PERMISSION_HIS_3 = [
  "tick_3_checkin_view",
  "tick_3_checkin_edit",
  "tick_3_fake_view",
  "tick_3_fake_edit",
  "tick_3_award_view",
  "tick_3_award_edit",
];
