import { Button, Input, InputNumber, Switch, Space, Badge, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { TEXT_DEF } from "../../../constant/text";
import { DAYS_WEEK } from "../../../constant/generate";
import "./style.css";
import { FiSave, FiTrash2 } from "react-icons/fi";
import API, { COMMON } from "../../../network/api";
import ModalCommon from "../../../components/Modal";
import moment from "moment";
import wheel2Img from '../../../image/wheel_2.png'

const Wheel2 = ({
  maintenance2,
  setMaintenance2,
  coinUsed2,
  setCoinUsed2,
  multiple2,
  setMultiple2,
  prizeList2,
  setPrizeList2,
  handleUpdate,
  showModalConfirm,
  loading
}) => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const roleLocal = localStorage.getItem("roles");
  const roleArr = roleLocal?.split(",");

  const onChangePrizeItem = (e, itemId = '') => {
    const prizeListUpdate = prizeList2.map(item => {
      if (item.id === itemId) {
        return { ...item, value2: e.toString() };
      }
      return item;
    });

    setPrizeList2(prizeListUpdate)
  }

  return (
    <>
      {
        loading ?
          <div className="col-12 d-flex justify-content-center">
            <Spin size="large" />
          </div>
          :
          <div className="col-12">
            <div className="col-12" style={{ display: "flex", alignItems: "center" }}>
              <div className="col-4 d-flex justify-content-between fs-5">
                {TEXT_DEF.text0057}:
                <Switch
                  checkedChildren="Bật"
                  unCheckedChildren="Tắt"
                  checked={maintenance2?.value === '1' ? true : false}
                  style={{ width: 80, marginTop: "5px" }}
                  onChange={(e) =>
                    setMaintenance2((prev) => ({
                      ...prev,
                      value: e == true ? '1' : '0',
                    }))
                  }
                />
              </div>
              {auth?.role === "supper" ||
                (auth?.role === "admin" && roleArr?.some(
                  (el) => (el === "wheel_2_sys_edit")
                )) ?
                <div className="col-8" style={{ display: "flex" }}>
                  <Button
                    type="primary"
                    onClick={(e) => showModalConfirm(e, "maintenance2")}
                    style={{
                      marginLeft: "2rem",
                    }}
                  >
                    {TEXT_DEF.update}
                  </Button>
                </div>
                : null}
            </div>

            <div className="col-12 mt-4" style={{ display: "flex", alignItems: "center" }}>
              <div className="col-4 d-flex justify-content-between fs-5">
                {TEXT_DEF.coin}:
                <InputNumber
                  name="timeCycle"
                  min={0}
                  max={99999999}
                  style={{ width: 80 }}
                  value={coinUsed2?.value}
                  onChange={(e) =>
                    setCoinUsed2((prev) => ({
                      ...prev,
                      value: e,
                    }))
                  }
                />
              </div>
              {auth?.role === "supper" ||
                (auth?.role === "admin" && roleArr?.some(
                  (el) => (el === "wheel_2_sys_edit")
                )) ?
                <div className="col-8" style={{ display: "flex" }}>
                  <div
                    className="col-2"
                    style={{ display: "flex", marginLeft: "2rem", }}
                  >
                    <Button
                      htmlType="submit"
                      type="primary"
                      onClick={(e) => showModalConfirm(e, "coinUsed2")}
                    >
                      {TEXT_DEF.text0012}
                    </Button>
                  </div>
                </div>
                : null
              }
            </div>

            <div className="col-12 mt-4" style={{ display: "flex", alignItems: "center" }}>
              <div className="col-4 d-flex justify-content-between fs-5">
                {TEXT_DEF.multiple}:
                <InputNumber
                  name="timeCycle"
                  min={0}
                  max={99999999}
                  style={{ width: 80 }}
                  value={multiple2?.value}
                  onChange={(e) =>
                    setMultiple2((prev) => ({
                      ...prev,
                      value: e,
                    }))
                  }
                />
              </div>
              {auth?.role === "supper" ||
                (auth?.role === "admin" && roleArr?.some(
                  (el) => (el === "wheel_2_sys_edit")
                )) ?
                <div className="col-8" style={{ display: "flex" }}>
                  <div
                    className="col-2"
                    style={{ display: "flex", marginLeft: "2rem", }}
                  >
                    <Button
                      htmlType="submit"
                      type="primary"
                      onClick={(e) => showModalConfirm(e, "multiple2")}
                    >
                      {TEXT_DEF.text0012}
                    </Button>
                  </div>
                </div>
                : null
              }
            </div>

            <div className="col-12 mt-5" style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>
              <div className="col-12"
                style={{
                  display: "flex", flexWrap: "wrap", flexDirection: "row", alignItems: "center",
                }}>
                {prizeList2 && prizeList2.length > 0 && prizeList2.map((item, index) => (
                  <div key={index} style={{
                    display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", maxWidth: '180px', minWidth: '180px', margin: '10px',
                    border: "1px solid #8889",
                    borderRadius: "10px",
                    marginBottom: "30px",
                    padding: "10px",
                    boxShadow: "8px 6px 10px #888888",

                  }}>

                    <div className="col-3" style={{ display: "flex", alignItems: "center", justifyContent: "center", fontWeight: "500" }}>
                      Vị trí: {item?.value}
                    </div>

                    <div className="col-9" style={{ display: "flex", flexDirection: "column", textAlign: "center", justifyContent: "start" }}>
                      <span style={{ color: "#1677ff", fontWeight: "700" }}>{item?.value1} {!isNaN(Number(item?.value1)) ? 'Điểm' : ''}</span>
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "0.25rem" }}>
                        <span>Tỷ lệ: </span>
                        <InputNumber
                          name="timeCycle"
                          // defaultValue={0.1}
                          min={0}
                          max={1}
                          step={0.1}
                          style={{ width: 60 }}
                          value={item?.value2}
                          onChange={(e) => { onChangePrizeItem(e, item?.id) }
                          }
                        />
                      </div>
                    </div>


                  </div>
                ))}
              </div>
              {auth?.role === "supper" ||
                (auth?.role === "admin" && roleArr?.some(
                  (el) => (el === "wheel_2_sys_edit")
                )) ?
                <div style={{ display: "flex", justifyContent: "center", marginTop: "0.1rem" }}>
                  <Button
                    htmlType="submit"
                    type="primary"
                    onClick={(e) => { showModalConfirm(e, "prize2") }}
                  >
                    {TEXT_DEF.text0012}
                  </Button>
                </div>
                : null
              }
            </div>

            <div className="col-12 d-flex justify-content-center">
              <img className="mt-4" src={wheel2Img} alt="wheel1" width="300" height="300"></img>
            </div>
          </div>
      }
    </>
  );
};

export default Wheel2;
