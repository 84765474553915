export const PERMISSION = [
  {
    id: 0,
    value: "user_view",
    label: "Xem hội viên",
  },
  // {
  //   id: 1,
  //   value: "user_edit",
  //   label: "Tạo admin",
  // },
  {
    id: 2,
    value: "wheel_1_sys_view",
    label: "Xem hệ thống VQ Truyền Thống",
  },
  {
    id: 3,
    value: "wheel_1_sys_edit",
    label: "Cài đặt hệ thống VQ Truyền Thống",
  },
  {
    id: 4,
    value: "wheel_2_sys_view",
    label: "Xem hệ thống VQ Mạo Hiểm",
  },
  {
    id: 5,
    value: "wheel_2_sys_edit",
    label: "Cài đặt hệ thống VQ Mạo Hiểm",
  },
  {
    id: 6,
    value: "sys_rules_view",
    label: "Xem text thể lệ",
  },
  {
    id: 7,
    value: "sys_rules_edit",
    label: "Cập nhật text thể lệ",
  },
  {
    id: 8,
    value: "wheel_1_his_view",
    label: "Xem lịch sử VQ Truyền Thống",
  },
  {
    id: 9,
    value: "wheel_2_his_view",
    label: "Xem lịch sử VQ Mạo Hiểm",
  },
  // {
  //   id: 10,
  //   value: "admin_permissions_view",
  //   label: "Xem quyền hạn của admin",
  // },
  // {
  //   id: 11,
  //   value: "admin_permissions_edit",
  //   label: "Cập nhật quyền hạn của admin",
  // },
];
