import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Input, Button, DatePicker, Table, Select, Spin, Pagination } from 'antd';
import API, { LOG } from '../../network/api';
import moment from 'moment';
import 'moment-timezone';
import { downloadExcel } from "react-export-table-to-excel";
import { TEXT_DEF } from '../../constant/text';

const { Option } = Select;

const Logs = () => {

  const auth = localStorage.getItem('auth')

  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate()
  const [dateFrom, setDateFrom] = useState()
  const [dateTo, setDateTo] = useState()
  const [dataList, setDataList] = useState([])
  const [page, setPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalScore, setTotalScore] = useState()
  const [dataFilter, setDataFilter] = useState()

  const locale = {
    emptyText: TEXT_DEF.text0035
  }

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    getDataHis()
  }, [page, dataFilter]);

  const getDataHis = async () => {
    setLoading(true)
    let URL = `${LOG}/search?take=10&skip=${page}`
    if (dataFilter) {
      if (dataFilter?.username) {
        URL += `&username=${dataFilter?.username}`
      }

      if (dataFilter?.startDate) {
        URL += `&startDate=${dataFilter?.startDate}`
      }

      if (dataFilter?.endDate) {
        URL += `&endDate=${dataFilter?.endDate}`
      }
    }
    await API.get(URL)
      .then(response => {
        const result = response.data?.data?.result[0]
        const totalRed = response.data?.data?.result[1]
        if (result) {
          const dataRes = []
          result.map(item => {
            const newIt = {
              key: item?.id,
              id: item?.id,
              username: item?.username,
              action: defineAction(item?.action),
              message: item?.message,
              createdAt: item?.createdAt
            }
            dataRes.push(newIt)
          })

          setDataList(dataRes)
          setTotalRecord(totalRed)
          setTimeout(() => {
            setLoading(false)
          }, 1000);
        }
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
      })
  }

  const defineAction = (action) => {
    let txt = ""
    switch (action) {
      case "ADD_POINT_TO_HOME_PAGE": txt = "Cộng điểm về ví chính"; break;
      case "GET_DATA_FROM_HOME_PAGE": txt = "Lấy data từ trang chủ"; break;
    }
    return txt
  }

  const onSearch = () => {
    let params = {};
    if (form.getFieldValue('usernameFilter')) params.username = form.getFieldValue('usernameFilter')
    if (dateFrom) {
      params.startDate = moment(dateFrom).format("YYYY-MM-DD")
      if (!dateTo) params.endDate = "2123-12-31"
    }
    if (dateTo) {
      if (!dateFrom) params.endDate = "2023-01-01"
      params.endDate = moment(dateTo).format("YYYY-MM-DD")
    }

    setPage(1)
    setDataFilter(params?.username || params?.startDate || params?.endDate ? params : undefined)
  }

  const tableColums = [
    {
      title: 'STT',
      align: 'center',
      key: 'order',
      render: (text, record, index) => <span>{(page - 1) * 10 + (index + 1)}</span>
    },
    {
      title: TEXT_DEF.text0002,
      dataIndex: 'username',
      key: 'username'
    },
    {
      title: TEXT_DEF.text0117,
      dataIndex: 'action',
      key: 'action'
    },
    {
      title: TEXT_DEF.text0118,
      dataIndex: 'message',
      key: 'message'
    },
    {
      title: TEXT_DEF.text0119,
      key: 'createdAt',
      render: (record) =>
        moment(record?.createdAt).utcOffset(7).format('DD - MM - YYYY HH: mm: ss')
    }
  ]

  const onChangeDate = (e, type) => {
    if (e) {
      if (type === 'from')
        setDateFrom(moment(e.$d).startOf("day").format('yyyy-MM-DD'))
      else
        setDateTo(moment(e.$d).endOf("day").format('yyyy-MM-DD'))
    } else {
      if (type === 'from')
        setDateFrom(null)
      else
        setDateTo(null)
    }
  }

  const onChangePage = (page) => {
    setPage(page)
  }

  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  return (
    <div className='col-12'>
      <div className='col-12'>
        <Form form={form} layout="inline" autoComplete='off' onFinish={() => onSearch()}>
          <div className='col-12' style={{ marginBottom: 15, display: 'flex' }}>
            <div className='col-4'>
              <Form.Item
                name="usernameFilter"
                label={TEXT_DEF.text0002}
              >
                <Input
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </div>
            <div className='col-4'>
              <Form.Item
                name="dateFrom"
                label={TEXT_DEF.text0032}
              >
                <DatePicker
                  placeholder=''
                  onChange={(e) => onChangeDate(e, 'from')}
                  format={"DD-MM-YYYY"}
                  style={{ width: '100%' }}
                  disabledTime={d => !d || d.isSameOrAfter(dateTo ? moment(dateTo).add(1, 'd') : moment())}
                />
              </Form.Item>
            </div>
            <div className='col-4'>
              <Form.Item
                name="dateTo"
                label={TEXT_DEF.text0033}
              >
                <DatePicker
                  placeholder=''
                  onChange={(e) => onChangeDate(e, 'to')}
                  format={"DD-MM-YYYY"}
                  style={{ width: '100%' }}
                  disabledTime={d => !d || d.isSameOrBefore(dateFrom ? dateFrom : null) || d.isSameOrAfter(moment().add(1, 'd'))}
                />
              </Form.Item>
            </div>
          </div >
          <div className='payment-search'>
            <div>
              <Button htmlType='submit' type="primary">{TEXT_DEF.text0031}</Button>
            </div>
          </div>
        </Form >
      </div >
      <Spin spinning={loading} delay={500} size="large">
        <div style={{ display: 'flex', width: '100%', marginTop: 20 }}>
          <Table
            key='dataHis'
            locale={locale}
            columns={tableColums}
            dataSource={dataList}
            pagination={false}
            bordered
            style={{ width: '100%' }}
          />
        </div>
        <div style={{ display: 'flex', width: '100%', marginTop: 15, justifyContent: 'flex-end' }}>
          <Pagination
            current={page}
            total={totalRecord}
            onChange={onChangePage}
            defaultPageSize={10}
            showSizeChanger={false}
            showTotal={showTotal}
          />
        </div>
      </Spin>
    </div >
  )
}

export default Logs;