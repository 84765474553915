import React, { memo, useState } from "react";
import { LIST_CYCLE } from "../../../constant/generate";
import { TEXT_DEF } from "../../../constant/text";
import { Button, InputNumber, Switch, Spin } from "antd";
import wheel1Img from '../../../image/wheel_1.png'

const Wheel1 = ({
  maintenance1,
  setMaintenance1,
  coinUsed1,
  setCoinUsed1,
  multiple1,
  setMultiple1,
  prizeList1,
  setPrizeList1,
  handleUpdate,
  showModalConfirm,
  loading
}) => {
  console.log("prizeList1", prizeList1)
  const auth = JSON.parse(localStorage.getItem("auth"));
  const roleLocal = localStorage.getItem("roles");
  const roleArr = roleLocal?.split(",");

  const onChangePrizeItem = (e, itemId = '') => {
    const prizeListUpdate = prizeList1.map(item => {
      if (item.id === itemId) {
        return { ...item, value2: e.toString() };
      }
      return item;
    });

    setPrizeList1(prizeListUpdate)
  }

  return (
    <>
      {
        loading ?
          <div className="col-12 d-flex justify-content-center">
            <Spin size="large" />
          </div>
          :
          <div className="col-12">
            <div className="col-12" style={{ display: "flex", alignItems: "center" }}>
              <div className="col-4 d-flex justify-content-between fs-5">
                {TEXT_DEF.text0057}:
                <Switch
                  checkedChildren="Bật"
                  unCheckedChildren="Tắt"
                  checked={maintenance1?.value === '1' ? true : false}
                  style={{ width: 80, marginTop: "5px" }}
                  onChange={(e) =>
                    setMaintenance1((prev) => ({
                      ...prev,
                      value: e == true ? '1' : '0',
                    }))
                  }
                />
              </div>
              {auth?.role === "supper" ||
                (auth?.role === "admin" && roleArr?.some(
                  (el) => (el === "wheel_1_sys_edit")
                )) ?
                <div className="col-8" style={{ display: "flex" }}>
                  <Button
                    type="primary"
                    onClick={(e) => showModalConfirm(e, "maintenance1")}
                    style={{
                      marginLeft: "2rem",
                    }}
                  >
                    {TEXT_DEF.update}
                  </Button>
                </div>
                : null
              }
            </div>

            <div className="col-12 mt-4" style={{ display: "flex", alignItems: "center" }}>
              <div className="col-4 d-flex justify-content-between fs-5">
                {TEXT_DEF.coin}:
                <InputNumber
                  name="timeCycle"
                  min={0}
                  max={99999999}
                  style={{ width: 80 }}
                  value={coinUsed1?.value}
                  onChange={(e) =>
                    setCoinUsed1((prev) => ({
                      ...prev,
                      value: e,
                    }))
                  }
                />
              </div>
              {auth?.role === "supper" ||
                (auth?.role === "admin" && roleArr?.some(
                  (el) => (el === "wheel_1_sys_edit")
                )) ?
                <div className="col-8" style={{ display: "flex" }}>
                  <div
                    className="col-2"
                    style={{ display: "flex", marginLeft: "2rem", }}
                  >
                    <Button
                      htmlType="submit"
                      type="primary"
                      onClick={(e) => showModalConfirm(e, "coinUsed1")}
                    >
                      {TEXT_DEF.text0012}
                    </Button>
                  </div>
                </div>
                : null}
            </div>

            <div className="col-12 mt-4" style={{ display: "flex", alignItems: "center" }}>
              <div className="col-4 d-flex justify-content-between fs-5">
                {TEXT_DEF.multiple}:
                <InputNumber
                  className="text-center"
                  name="timeCycle"
                  min={0}
                  max={99999999}
                  style={{ width: 80 }}
                  value={multiple1?.value}
                  onChange={(e) =>
                    setMultiple1((prev) => ({
                      ...prev,
                      value: e,
                    }))
                  }
                />
              </div>
              {auth?.role === "supper" ||
                (auth?.role === "admin" && roleArr?.some(
                  (el) => (el === "wheel_1_sys_edit")
                )) ?
                <div className="col-8" style={{ display: "flex" }}>
                  <div
                    className="col-2"
                    style={{ display: "flex", marginLeft: "2rem", }}
                  >
                    <Button
                      htmlType="submit"
                      type="primary"
                      onClick={(e) => showModalConfirm(e, "multiple1")}
                    >
                      {TEXT_DEF.text0012}
                    </Button>
                  </div>
                </div>
                : null}
            </div>

            <div className="col-12 mt-5" style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>
              <div className="col-12"
                style={{
                  display: "flex", flexWrap: "wrap", flexDirection: "row", alignItems: "center",

                }}>
                {prizeList1 && prizeList1.length > 0 && prizeList1.map((item, index) => (
                  <div key={index} style={{
                    display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", maxWidth: '180px', minWidth: '180px', margin: '10px',
                    border: "1px solid #8889",
                    borderRadius: "10px",
                    marginBottom: "30px",
                    padding: "10px",
                    boxShadow: "8px 6px 10px #888888"
                  }}>

                    <div className="col-3" style={{ display: "flex", alignItems: "center", justifyContent: "center", fontWeight: "500" }}>
                      Vị trí: {item?.value}
                    </div>

                    <div className="col-9" style={{ display: "flex", flexDirection: "column", textAlign: "center", justifyContent: "start" }}>
                      <span style={{ color: "#1677ff", fontWeight: "700" }}>{item?.value1} Điểm</span>
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "0.25rem" }}>
                        <span>Tỷ lệ: </span>
                        <InputNumber
                          name="timeCycle"
                          // defaultValue={0.1}
                          min={0}
                          max={1}
                          step={0.1}
                          style={{ width: 60 }}
                          value={item?.value2}
                          onChange={(e) => onChangePrizeItem(e, item?.id)}
                        />
                      </div>
                    </div>


                  </div>
                ))}
              </div>
              {auth?.role === "supper" ||
                (auth?.role === "admin" && roleArr?.some(
                  (el) => (el === "wheel_1_sys_edit")
                )) ?
                <div style={{ display: "flex", justifyContent: "center", marginTop: "0.1rem" }}>
                  <Button
                    htmlType="submit"
                    type="primary"
                    onClick={(e) => { showModalConfirm(e, "prize1") }}
                  >
                    {TEXT_DEF.text0012}
                  </Button>
                </div>
                : null
              }
            </div>

            <div className="col-12 d-flex justify-content-center">
              <img className="mt-4" src={wheel1Img} alt="wheel1" width="300" height="300"></img>
            </div>
          </div >
      }

    </>
  );
};

export default memo(Wheel1);
