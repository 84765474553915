import React, { useState } from "react";
import { Menu } from "antd";
import { IdcardFilled, FilterFilled, SettingFilled } from "@ant-design/icons";
import "./style.css";
import { Link, useLocation } from "react-router-dom";
import { TEXT_DEF } from "../../constant/text";
import { SETTING_HIS, SETTING_SYTEMS } from "../../constant/generate";

const SideBarCustom = () => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const roleLocal = localStorage.getItem("roles");
  const roleArr = roleLocal?.split(",");

  let location = useLocation();
  const [current, setCurrent] = useState(
    location.pathname === "/" || location.pathname === ""
      ? "/account"
      : location.pathname
  );

  const handleClick = (e) => {
    setCurrent(e.key);
  };

  return (
    <>
      <div className="logo">Hậu đài Vòng quay may mắn 8DAY</div>
      <Menu onClick={handleClick} mode="inline" selectedKeys={[current]}>
        {auth?.role === "supper" ||
          (auth?.role === "admin" &&
            (roleArr?.indexOf("user_view") > -1 ||
              roleArr?.indexOf("user_edit") > -1)) ? (
          <Menu.Item
            style={{ padding: "0 16px" }}
            key="/account"
            icon={<IdcardFilled className="menu-icon" />}
          >
            <Link to="/account">{TEXT_DEF.text0024}</Link>
          </Menu.Item>
        ) : null}

        {auth?.role === "supper" ||
          (auth?.role === "admin" &&
            SETTING_HIS.some((el) => roleArr.includes(el))) ? (
          <Menu.Item
            style={{ padding: "0 16px" }}
            key="/growHis"
            icon={<FilterFilled className="menu-icon" />}
          >
            <Link to="/growHis">{TEXT_DEF.text0123}</Link>
          </Menu.Item>
        ) : null}

        {auth?.role === "supper" ||
          (auth.role === "admin" &&
            roleArr.some(
              (el) => el === "sys_rules_edit" || el === "sys_rules_view"
            )) ? (
          <Menu.Item
            style={{ padding: "0 16px" }}
            key="/setting-rule"
            icon={<SettingFilled className="menu-icon" />}
          >
            <Link key="/setting-rule" to="/setting-rule">
              {TEXT_DEF.setting_rule}
            </Link>
          </Menu.Item>
        ) : null}

        {(auth?.role === "supper" ||
          roleArr.some(
            (el) => (el === "wheel_1_sys_view" || el === "wheel_2_sys_view" ||
              el === "wheel_1_sys_edit" || el === "wheel_2_sys_edit")
          ))
          ? (
            <Menu.Item
              style={{ padding: "0 16px" }}
              key="/settingSys"
              icon={<SettingFilled className="menu-icon" />}
            >
              <Link to="/settingSys">{TEXT_DEF.text0051}</Link>
            </Menu.Item>
          ) : null}

        {/* {auth?.role === "supper" ||
          (auth.role === "admin" &&
            roleArr.some(
              (el) => el === "tick_3_fake_view" || el === "tick_3_fake_edit"
            )) ? (
          <Menu.Item
            style={{ padding: "0 16px" }}
            key="/list-user-fake"
            icon={<SettingFilled className="menu-icon" />}
          >
            <Link key="/list-user-fake" to="/list-user-fake">
              {TEXT_DEF.list_user_fake}
            </Link>
          </Menu.Item>
        ) : null} */}
      </Menu>
    </>
  );
};

export default SideBarCustom;
