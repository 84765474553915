import { Radio } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { TEXT_DEF } from "../../constant/text";
import FormRule from "../../components/formRule";
import API, { COMMON } from "../../network/api";
import ModalCommon from "../../components/Modal";

const SettingRule = () => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const roleLocal = localStorage.getItem("roles");
  const roleArr = roleLocal?.split(",");

  const [indexTab, setIndexTab] = useState(0);
  const [textRuleWheel1, setTextRuleWheel1] = useState();
  const [textRuleWheel2, setTextRuleWheel2] = useState();
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);
  const [keyword, setKeyWord] = useState();

  useEffect(() => {
    if (indexTab == 0) {
      getDataRuleWheel1();
    }
    else {
      getDataRuleWheel2();
    }
  }, []);

  useEffect(() => {
    if (indexTab == 0) {
      getDataRuleWheel1();
    }
    else {
      getDataRuleWheel2();
    }
  }, [indexTab]);

  const getDataRuleWheel1 = async () => {
    try {
      const kw = encodeURIComponent(
        JSON.stringify({ module: "WHEEL1", item: "wheel_1_award_congul" })
      );

      const res = await API.get(
        `${COMMON}/all?take=10&skip=1&order=DESC&keyword=${kw}`
      );
      const data = res.data.data.result[0];

      if (res.data.message && data) {
        setTextRuleWheel1(data[0]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getDataRuleWheel2 = async () => {
    try {
      const kw = encodeURIComponent(
        JSON.stringify({ module: "WHEEL2", item: "wheel_2_award_congul" })
      );

      const res = await API.get(
        `${COMMON}/all?take=10&skip=1&order=DESC&keyword=${kw}`
      );
      const data = res.data.data.result[0];

      if (res.data.message && data) {
        setTextRuleWheel2(data[0]);
      }
    } catch (err) {
      console.log(err);
    }
  };



  const handleUpdate = () => {
    switch (keyword) {
      case "wheel1":
        return handleUpdateRuleWheel1();
      case "wheel2":
        return handleUpdateRuleWheel2();
    }
  };

  const handleUpdateRuleWheel1 = async () => {
    try {
      const res = await API.patch(`${COMMON}/${textRuleWheel1.id}`, {
        value: textRuleWheel1.value,
      });
      if (res?.data?.data?.result && res?.data?.data?.code == 20004) {
        setIsShowModalConfirm(false)
        setIsShowModalSuccess(true);
      } else {
        alert('Cập nhật hệ thống thất bại !')
        window?.location?.reload();
        setIsShowModalConfirm(false);
        await getDataRuleWheel1();


      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateRuleWheel2 = async () => {
    try {
      try {
        const res = await API.patch(`${COMMON}/${textRuleWheel2.id}`, {
          value: textRuleWheel2.value,
        });
        if (res?.data?.data?.result && res?.data?.data?.code == 20004) {
          setIsShowModalConfirm(false)
          setIsShowModalSuccess(true);
        } else {
          alert('Cập nhật hệ thống thất bại !')
          window?.location?.reload();
          setIsShowModalConfirm(false)
          getDataRuleWheel2()
        }
      } catch (err) {
        console.log(err);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleShowModalConfirm = useCallback((e, key) => {
    setIsShowModalConfirm(true);
    setKeyWord(key);
  }, []);

  return (
    <div className="col-12">
      <div
        className="col-12"
        style={{
          borderBottom: "1px solid #CCC",
          paddingBottom: "2rem",
          marginBottom: "2rem",
        }}
      >
        <Radio.Group
          value={indexTab}
          onChange={(e) => setIndexTab(e.target.value)}
        >
          <Radio.Button value={0}>{TEXT_DEF.wheel_1}</Radio.Button>
          <Radio.Button value={1}>{TEXT_DEF.wheel_2}</Radio.Button>
        </Radio.Group>
      </div>

      <div className="d-flex w-full justify-content-end "><span className="text-danger fw-bold">* </span>: với mỗi ký tự "/n" tương ứng đoạn văn sẽ xuống dòng ở USER </div>
      {indexTab === 0 ? (
        <FormRule
          onHandleChangeInput={(e) =>
            setTextRuleWheel1({ ...textRuleWheel1, value: e.target.value })
          }
          auth={auth}
          textRule={textRuleWheel1?.value}
          roleArr={roleArr}
          onShowModalConfirm={(e) => handleShowModalConfirm(e, "wheel1")}
        />
      ) : indexTab === 1 ? (
        <FormRule
          onHandleChangeInput={(e) =>
            setTextRuleWheel2({ ...textRuleWheel2, value: e.target.value })
          }
          auth={auth}
          textRule={textRuleWheel2?.value}
          roleArr={roleArr}
          onShowModalConfirm={(e) => handleShowModalConfirm(e, "wheel2")}
        />
      ) : null}
      {isShowModalConfirm && (
        <ModalCommon
          isShow={isShowModalConfirm}
          message={TEXT_DEF.text0062}
          txtBtnAcc={TEXT_DEF.text0008}
          textBtnClose={TEXT_DEF.text0023}
          actionNext={handleUpdate}
          close={() => setIsShowModalConfirm(false)}
        />
      )}
      {isShowModalSuccess && (
        <ModalCommon
          isShow={isShowModalSuccess}
          message={TEXT_DEF.text0063}
          txtBtnAcc={TEXT_DEF.text0007}
          actionNext={() => {
            setIsShowModalSuccess(false);
          }}
          close={() => {
            setIsShowModalSuccess(false);
          }}
        />
      )}
    </div>
  );
};

export default SettingRule;
