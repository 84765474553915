import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Table,
  Pagination,
  Spin,
  Radio,
  Modal
} from "antd";
import API, { HISTORY_ALL, HISTORY_DETAIL } from "../../network/api";
import moment from "moment";
import "moment-timezone";
import { downloadExcel } from "react-export-table-to-excel";
import { TEXT_DEF } from "../../constant/text";
import { HISTORY_STATUS } from "../../constant/historry";

const GrowHistory = () => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const navigate = useNavigate();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [dataFilterTab1, setDataFilterTab1] = useState();
  const [dataFilterTab2, setDataFilterTab2] = useState();
  const [dataList, setDataList] = useState([]);
  const [pageTab1, setPageTab1] = useState(1);
  const [totalRecordTab1, setTotalRecordTab1] = useState(0);
  const [pageTab2, setPageTab2] = useState(1);
  const [totalRecordTab2, setTotalRecordTab2] = useState(0);
  const [loading, setLoading] = useState(false);
  const [indexTab, setIndexTab] = useState(0);

  const [modalOpen, setModalOpen] = useState('');
  const [dataModal, setDataModal] = useState([]);

  const roleLocal = localStorage.getItem("roles");
  const roleArr = roleLocal?.split(",");

  const locale = {
    emptyText: TEXT_DEF.text0035,
  };

  const showModal = async (hisId = 0) => {
    setModalOpen(hisId);
    setLoading(true)
    let URL = `${HISTORY_DETAIL}/${hisId}`;
    const listData = await API.get(URL);

    const result = listData.data?.data?.result[0];
    if (result?.length > 0) {
      setDataModal(result)
    }
    setLoading(false)
  };

  useEffect(() => {
    if (
      auth?.role === "supper" ||
      (auth?.role === "admin" &&
        roleArr.some((el) => el === "wheel_1_his_view"))
    ) {
      return setIndexTab(0);
    }
    if (
      auth?.role === "supper" ||
      (auth?.role === "admin" &&
        roleArr.some((el) => el === "wheel_2_his_view"))
    ) {
      return setIndexTab(1);
    }

  }, []);

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    if (indexTab === 0) {
      getDataTab1();
    } else {
      getDataTab2();
    }
  }, [pageTab1, , pageTab2, dataFilterTab1, dataFilterTab2]);

  useEffect(() => {
    if (indexTab === 0) {
      setDataFilterTab1(undefined)
      getDataTab1();
    } else {
      setDataFilterTab2(undefined)
      getDataTab2();
    }
  }, [indexTab]);

  const onChangeTab = (e) => {
    setIndexTab(e.target.value);
    setPageTab1(1);
    setPageTab2(1);
    setDateFrom(null);
    setDateTo(null);
    form.setFieldValue("usernameFilter", undefined);
    form.setFieldValue("codeFilter", undefined);
    form.setFieldValue("dateFrom", undefined);
    form.setFieldValue("dateTo", undefined);
  };

  const getDataTab1 = async () => {
    setLoading(true);
    let kw = dataFilterTab1
      ? encodeURIComponent(JSON.stringify(dataFilterTab1))
      : encodeURIComponent(JSON.stringify({ "typeGame": "WHEEL1", "startDate": "", "endDate": "" }));

    let URL = `${HISTORY_ALL}?take=10&skip=${pageTab1}&order=DESC&keyword=${kw}`;
    const listData = await API.get(URL);

    const result = listData.data?.data?.result[0];
    if (result?.length > 0) {
      let dataCV = [];
      result?.map((item) => {
        let itemNew = {
          key: item?.id,
          ft: item?.ft,
          username: item?.user?.username || null,
          timeStart: item?.timeStart
            ? moment(item?.timeStart).utcOffset(7).format("DD-MM-YYYY HH:mm:ss")
            : null,
          timeEnd: item.timeEnd
            ? moment(item?.timeEnd)
              .utcOffset(7)
              .format("DD-MM-YYYY HH:mm:ss")
            : null,
          turnNum: item?.turnNum,
          score: item?.score,
          coinUsed: item?.coinUsed,
          helping: item?.helping,
          helping_used: item?.helpingUsed,
          status: HISTORY_STATUS[item?.status],
          sendStatus: item?.sendStatus,
          multiple: item?.multiple,
          details: 'Xem chi tiết',
        };
        dataCV.push(itemNew);
      });
      setDataList(dataCV);
      setTotalRecordTab1(listData.data?.data?.result[1]);
    } else {
      setDataList([]);
    }
    setLoading(false);
  };

  const exportDataTab1 = async () => {
    setLoading(true);
    let kw = dataFilterTab1
      ? encodeURIComponent(JSON.stringify(dataFilterTab1))
      : encodeURIComponent(JSON.stringify({ "typeGame": "WHEEL1", "startDate": "", "endDate": "" }));

    let URL = `${HISTORY_ALL}?take=1000000&skip=${pageTab1}&order=DESC&keyword=${kw}`;
    const listData = await API.get(URL);

    const result = listData.data?.data?.result[0];
    if (result?.length > 0) {
      let dataCV = [];
      result?.map((item) => {
        let itemNew = {
          username: item?.user?.username || null,
          ft: item?.ft,
          timeStart: item?.timeStart
            ? moment(item?.timeStart).utcOffset(7).format("DD-MM-YYYY HH:mm:ss")
            : null,
          timeEnd: item.timeEnd
            ? moment(item?.timeEnd)
              .utcOffset(7)
              .format("DD-MM-YYYY HH:mm:ss")
            : null,
          turnNum: item?.turnNum,
          score: item?.score,
          coinUsed: item?.coinUsed,
          status: HISTORY_STATUS[item?.status],
          sendStatus: item?.sendStatus,
          multiple: item?.multiple,
        };
        dataCV.push(itemNew);
      });
      const header = [
        TEXT_DEF.text0002,
        TEXT_DEF.ft,
        TEXT_DEF.time_play,
        TEXT_DEF.time_play_confirm,
        TEXT_DEF.turn,
        TEXT_DEF.score,
        TEXT_DEF.used_coin,
        TEXT_DEF.play_process,
        TEXT_DEF.score_submission_process,
        TEXT_DEF.round,
      ];

      downloadExcel({
        fileName: "Lịch sử Vòng quay Truyền Thống",
        sheet: "Vòng quay Truyền Thống",
        tablePayload: {
          header: header,
          body: dataCV,
        },
      });
    }
    setLoading(false);
  };

  const getDataTab2 = async () => {
    setLoading(true);
    let kw = dataFilterTab2
      ? encodeURIComponent(JSON.stringify(dataFilterTab2))
      : encodeURIComponent(JSON.stringify({ "typeGame": "WHEEL2", "startDate": "", "endDate": "" }));

    let URL = `${HISTORY_ALL}?take=10&skip=${pageTab2}&order=DESC&keyword=${kw}`;
    const listData = await API.get(URL);
    const result = listData.data?.data?.result[0];
    if (result?.length > 0) {
      let dataCV = [];
      result?.map((item) => {
        let itemNew = {
          key: item?.id,
          ft: item?.ft,
          username: item?.user?.username || null,
          timeStart: item?.timeStart
            ? moment(item?.timeStart).utcOffset(7).format("DD-MM-YYYY HH:mm:ss")
            : null,
          timeEnd: item.timeEnd
            ? moment(item?.timeEnd)
              .utcOffset(7)
              .format("DD-MM-YYYY HH:mm:ss")
            : null,
          turnNum: item?.turnNum,
          score: item?.score,
          coinUsed: item?.coinUsed,
          helping: item?.helping,
          helping_used: item?.helpingUsed,
          status: HISTORY_STATUS[item?.status],
          sendStatus: item?.sendStatus,
          multiple: item?.multiple,
          details: 'Xem chi tiết',
        };
        dataCV.push(itemNew);
      });
      setDataList(dataCV);
      setTotalRecordTab2(listData.data?.data?.result[1]);
    } else {
      setDataList([]);
    }
    setLoading(false);
  };

  const exportDataTab2 = async () => {
    setLoading(true);
    let kw = dataFilterTab2
      ? encodeURIComponent(JSON.stringify(dataFilterTab2))
      : encodeURIComponent(JSON.stringify({ "typeGame": "WHEEL2", "startDate": "", "endDate": "" }));
    let URL = `${HISTORY_ALL}?take=10000&skip=${pageTab2}&order=DESC&keyword=${kw}`;
    const listData = await API.get(URL);
    const result = listData.data?.data?.result[0];
    if (result?.length > 0) {
      let dataCV = [];
      result?.map((item) => {
        let itemNew = {
          username: item?.user?.username || null,
          ft: item?.ft,
          timeStart: item?.timeStart
            ? moment(item?.timeStart).utcOffset(7).format("DD-MM-YYYY HH:mm:ss")
            : null,
          timeEnd: item.timeEnd
            ? moment(item?.timeEnd)
              .utcOffset(7)
              .format("DD-MM-YYYY HH:mm:ss")
            : null,
          turnNum: item?.turnNum,
          score: item?.score,
          coinUsed: item?.coinUsed,
          status: HISTORY_STATUS[item?.status],
          sendStatus: item?.sendStatus,
          multiple: item?.multiple,
        };
        dataCV.push(itemNew);
      });
      const header = [
        TEXT_DEF.text0002,
        TEXT_DEF.ft,
        TEXT_DEF.time_play,
        TEXT_DEF.time_play_confirm,
        TEXT_DEF.turn,
        TEXT_DEF.score,
        TEXT_DEF.used_coin,
        TEXT_DEF.play_process,
        TEXT_DEF.score_submission_process,
        TEXT_DEF.round,
      ];
      downloadExcel({
        fileName: "Lịch sử Vòng quay Mạo hiểm ",
        sheet: "Vòng quay Mạo hiểm",
        tablePayload: {
          header: header,
          body: dataCV,
        },
      });
    } else {
      setDataList([]);
    }
    setLoading(false);
  };


  const onSearch = () => {
    let params = { typeGame: indexTab == 0 ? "WHEEL1" : "WHEEL2", startDate: "", endDate: "" };

    if (form.getFieldValue("usernameFilter"))
      params.username = form.getFieldValue("usernameFilter").trim().toLowerCase();
    if (form.getFieldValue("codeFilter"))
      params.ft = form.getFieldValue("codeFilter").trim().toLowerCase();
    if (dateFrom) {
      params.startDate = moment(dateFrom).format("YYYY-MM-DD");
      if (!dateTo) params.endDate = "2125-12-31";
    }
    if (dateTo) {
      params.endDate = moment(dateTo).format("YYYY-MM-DD");
      if (!dateFrom) params.endDate = "2023-01-01";
    }

    if (indexTab == 0) {
      setDataFilterTab1(params ? params : undefined);
      setPageTab1(1);
    } else {
      setDataFilterTab2(params ? params : undefined);
      setPageTab2(1);
    }

  };

  const tableColumsTab1 = [
    {
      title: TEXT_DEF.text0002,
      key: "username",
      dataIndex: "username",
      width: 150,
    },
    {
      title: TEXT_DEF.ft,
      dataIndex: "ft",
      key: "ft",
    },
    {
      title: TEXT_DEF.time_play,
      dataIndex: "timeStart",
      key: "timeStart",
    },
    {
      title: TEXT_DEF.time_play_confirm,
      dataIndex: "timeEnd",
      key: "timeEnd",
    },

    {
      title: TEXT_DEF.turn,
      key: "turnNum",
      dataIndex: "turnNum",
    },
    {
      title: TEXT_DEF.score,
      key: "score",
      dataIndex: "score",
    },
    {
      title: TEXT_DEF.used_coin,
      key: "coinUsed",
      dataIndex: "coinUsed",
    },
    {
      title: TEXT_DEF.play_process,
      key: "status",
      dataIndex: "status",
    },
    {
      title: TEXT_DEF.score_submission_process,
      key: "sendStatus",
      dataIndex: "sendStatus",
    },
    {
      title: TEXT_DEF.round,
      key: "multiple",
      dataIndex: "multiple",
    },
    {
      title: TEXT_DEF.details,
      key: "details",
      dataIndex: "details",
      render: (_, record) =>
      (<>
        <span style={{ cursor: "pointer", color: "#1677ff" }}
          onClick={() => showModal(record.key)}
        >
          {record?.details}
        </span>
        {modalOpen && (modalOpen === record.key) &&
          <Modal title="Chi tiết" open={modalOpen} onCancel={() => setModalOpen('')}
            footer={<></>}>
            {loading ? <Spin />
              :
              <div style={{ maxHeight: "70vh", overflowY: "auto" }}>
                {dataModal && dataModal.length > 0 && dataModal.map((item) => (
                  <div style={{
                    display: "flex", flexDirection: "column", borderBottom: "1px solid #CCC",
                    padding: "0.5rem 0",
                  }}>
                    {/* <span>Bắt đầu: {moment(item?.createdAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss")}</span> */}
                    <span style={{ display: "flex", flexDirection: "row", marginLeft: "2rem" }}>
                      <span style={{ fontWeight: '500' }}> Lượt {item?.turn}:</span>
                      <span className='mx-1'>{item?.pointCurrent}</span>điểm,
                      <span className='mx-1' style={{ color: "#1677ff" }}> Nhận được {item?.description}</span>
                    </span>
                    <span style={{ marginLeft: "4rem" }}>
                      Kết thúc: {moment(item?.updatedAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss")}
                    </span>
                  </div>
                ))}
              </div>
            }
          </Modal>
        }
      </>)
    },
  ];

  const tableColumsTab2 = [
    {
      title: TEXT_DEF.text0002,
      dataIndex: "username",
      key: "username",
    },
    {
      title: TEXT_DEF.ft,
      dataIndex: "ft",
      key: "ft",
    },
    {
      title: TEXT_DEF.time_play,
      dataIndex: "timeStart",
      key: "timeStart",
    },
    {
      title: TEXT_DEF.time_play_confirm,
      dataIndex: "timeEnd",
      key: "timeEnd",
    },
    {
      title: TEXT_DEF.score,
      key: "score",
      dataIndex: "score",
    },
    {
      title: TEXT_DEF.used_coin,
      key: "coinUsed",
      dataIndex: "coinUsed",
    },
    {
      title: TEXT_DEF.helping,
      key: "helping",
      dataIndex: "helping",
    },
    {
      title: TEXT_DEF.helping_used,
      key: "helping_used",
      dataIndex: "helping_used",
    },
    {
      title: TEXT_DEF.play_process,
      key: "status",
      dataIndex: "status",
    },
    {
      title: TEXT_DEF.score_submission_process,
      key: "sendStatus",
      dataIndex: "sendStatus",
    },
    {
      title: TEXT_DEF.round,
      key: "multiple",
      dataIndex: "multiple",
    },
    {
      title: TEXT_DEF.details,
      key: "details",
      dataIndex: "details",
      render: (_, record) => (<>
        <span style={{ cursor: "pointer", color: "#1677ff" }}
          onClick={() => showModal(record.key)}
        >
          {record?.details}
        </span>
        {modalOpen && (modalOpen === record.key) &&
          <Modal title="Chi tiết" open={modalOpen} onCancel={() => setModalOpen('')}
            footer={<></>}>
            {loading ? <Spin />
              :
              <div style={{ maxHeight: "70vh", overflowY: "auto" }}>
                {dataModal && dataModal.length > 0 && dataModal.map((item) => (
                  <div style={{
                    display: "flex", flexDirection: "column", borderBottom: "1px solid #CCC",
                    padding: "0.5rem 0",
                  }}>
                    <span>Bắt đầu: {moment(item?.createdAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss")}</span>
                    <span style={{ display: "flex", flexDirection: "row", marginLeft: "2rem" }}>
                      <span style={{ fontWeight: '500' }}>Vòng {item?.turn}: </span>
                      {item?.reward} {!isNaN(+item?.reward) ? ' điểm' : ''}, <span className="mx-1" style={{ color: "#1677ff" }}> Hiện có {item?.pointCurrent} điểm
                      </span>
                    </span>
                    <span style={{ marginLeft: "4rem" }}>
                      Kết thúc: {moment(item?.updatedAt).utcOffset(7).format("DD-MM-YYYY HH:mm:ss")}
                    </span>
                  </div>
                ))}
              </div>
            }
          </Modal>
        }
      </>)
    },
  ];

  const onChangeDate = (e, type) => {
    console.log(moment(e).endOf("day").format('yyyy-MM-DD'))
    if (e) {
      if (type === "from") setDateFrom(moment(e.$d).startOf("day").format('yyyy-MM-DD'));
      else setDateTo(moment(e.$d).endOf("day").format('yyyy-MM-DD'));
    } else {
      if (type === "from") setDateFrom(null);
      else setDateTo(null);
    }
  };

  const onChangePage = (page) => {
    if (indexTab === 0) {
      setPageTab1(page);
    } else if (indexTab === 1) {
      setPageTab2(page);
    }
  };

  const showTotal = (total) => `Tổng cộng ${total} dữ liệu`;

  const renderTab1 = () => {
    return (
      <>
        <div className="col-12">
          <Form
            form={form}
            layout="inline"
            autoComplete="off"
            onFinish={() => onSearch()}
          >
            <div
              className="col-12"
              style={{ marginBottom: 15, display: "flex" }}
            >
              <div className="col-4">
                <Form.Item name="usernameFilter" label={TEXT_DEF.text0002}>
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateFrom" label={TEXT_DEF.text0032}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "from")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) =>
                      !d ||
                      d.isSameOrAfter(dateTo && moment(dateTo).add(1, "d"))
                    }
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateTo" label={TEXT_DEF.text0033}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "to")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) =>
                      !d || d.isSameOrBefore(dateFrom ? dateFrom : null)
                    }
                  />
                </Form.Item>
              </div>

            </div>
            <div className="col-12">
              <div className="col-4">
                <Form.Item name="codeFilter" label={TEXT_DEF.text_code}>
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </div>
            </div>
            <div className="payment-search">
              <Button htmlType="submit" type="primary">
                {TEXT_DEF.text0031}
              </Button>
              <Button
                style={{ marginLeft: 20 }}
                onClick={exportDataTab1}
                htmlType="button"
                type="primary"
              >
                {TEXT_DEF.text0050}
              </Button>
            </div>
          </Form>
        </div>
        <Spin spinning={loading} delay={500} size="large">
          <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
            <Table
              key="dataHis"
              locale={locale}
              columns={tableColumsTab1}
              dataSource={dataList}
              pagination={false}
              bordered
              style={{ width: "100%", textAlign: "center" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              marginTop: 15,
              justifyContent: "flex-end",
            }}
          >
            <Pagination
              current={pageTab1}
              total={totalRecordTab1}
              onChange={onChangePage}
              defaultPageSize={10}
              showSizeChanger={false}
              showTotal={showTotal}
            />
          </div>
        </Spin>
      </>
    );
  };

  const renderTab2 = () => {
    return (
      <>
        <div className="col-12">
          <Form
            form={form}
            layout="inline"
            autoComplete="off"
            onFinish={() => onSearch()}
          >
            <div
              className="col-12"
              style={{ marginBottom: 15, display: "flex" }}
            >
              <div className="col-4">
                <Form.Item name="usernameFilter" label={TEXT_DEF.text0002}>
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateFrom" label={TEXT_DEF.text0032}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "from")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) =>
                      !d ||
                      d.isSameOrAfter(dateTo && moment(dateTo).add(1, "d"))
                    }
                  />
                </Form.Item>
              </div>
              <div className="col-4">
                <Form.Item name="dateTo" label={TEXT_DEF.text0033}>
                  <DatePicker
                    placeholder=""
                    onChange={(e) => onChangeDate(e, "to")}
                    format={"DD-MM-YYYY"}
                    style={{ width: "100%" }}
                    disabledTime={(d) =>
                      !d || d.isSameOrBefore(dateFrom ? dateFrom : null)
                    }
                  />
                </Form.Item>
              </div>
            </div>
            <div className="col-12">
              <div className="col-4">
                <Form.Item name="codeFilter" label={TEXT_DEF.text_code}>
                  <Input style={{ width: "100%" }} />
                </Form.Item>
              </div>
            </div>
            <div className="payment-search">
              <Button htmlType="submit" type="primary">
                {TEXT_DEF.text0031}
              </Button>
              <Button
                style={{ marginLeft: 20 }}
                onClick={exportDataTab2}
                htmlType="button"
                type="primary"
              >
                {TEXT_DEF.text0050}
              </Button>
            </div>
          </Form>
        </div>
        <Spin spinning={loading} delay={500} size="large">
          <div style={{ display: "flex", width: "100%", marginTop: 20 }}>
            <Table
              key="dataHis"
              locale={locale}
              columns={tableColumsTab2}
              dataSource={dataList}
              pagination={false}
              bordered
              style={{ width: "100%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              marginTop: 15,
              justifyContent: "flex-end",
            }}
          >
            <Pagination
              current={pageTab2}
              total={totalRecordTab2}
              onChange={onChangePage}
              defaultPageSize={10}
              showSizeChanger={false}
              showTotal={showTotal}
            />
          </div>
        </Spin>
      </>
    );
  };



  return (
    <div className="col-12">
      <div
        className="col-12"
        style={{
          borderBottom: "1px solid #CCC",
          paddingBottom: "2rem",
          marginBottom: "2rem",
        }}
      >
        <Radio.Group value={indexTab} onChange={onChangeTab}>
          {auth?.role === "supper" ||
            (auth?.role === "admin" &&
              roleArr.some(
                (el) => el === "wheel_1_his_view"
              )) ? (
            <Radio.Button value={0}>{TEXT_DEF.wheel_1}</Radio.Button>
          ) : null}
          {auth?.role === "supper" ||
            (auth?.role === "admin" &&
              roleArr.some(
                (el) => el === "wheel_2_his_view"
              )) ? (
            <Radio.Button value={1}>{TEXT_DEF.wheel_2}</Radio.Button>
          ) : null}
        </Radio.Group>
      </div>

      {indexTab === 0
        ? renderTab1()
        : indexTab === 1
          ? renderTab2()
          : null}

    </div>
  );
};

export default GrowHistory;
