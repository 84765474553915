import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import { Form, Input, Button, Select, Tooltip } from "antd";
import API, { REGISTER, USER } from "../../network/api";
import { TEXT_DEF } from "../../constant/text";
const { Option } = Select;

const CreateAccount = () => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const [listRole, setListRole] = useState([]);
  const navigate = useNavigate();
  const indexTabLocal = localStorage.getItem("indexLocalCreate") || 2;
  const passwordUserDf = "User12345!@#";
  const [errorMess, setErrorMess] = useState();
  const auth = JSON.parse(localStorage.getItem("auth"));
  const roleLocal = localStorage.getItem("roles");
  const roleArr = roleLocal?.split(",");

  useEffect(() => {
    let roleLocal = ["supper", "admin"];
    let roles = [];

    if (auth === "supper") {
      roleLocal = ["supper", "admin"];
      const newRl0 = {
        id: 0,
        value: roleLocal[0],
        label: roleLocal[0],
      };
      roles.push(newRl0);
      const newRl1 = {
        id: 1,
        value: roleLocal[1],
        label: roleLocal[1],
      };
      roles.push(newRl1);
    } else {
      roleLocal = ["admin"];
      const newRl0 = {
        id: 0,
        value: roleLocal[0],
        label: roleLocal[0],
      };
      roles.push(newRl0);
    }

    setListRole(roles);
  }, []);

  useEffect(() => {
    forceUpdate({});
  }, []);

  const onCreate = async () => {
    const params = {
      username: form.getFieldValue("username"),
      password:
        indexTabLocal === "1" ? passwordUserDf : form.getFieldValue("password"),
    };

    await API.post(REGISTER, params).then((response) => {
      if (response.data.data.result) {
        if (indexTabLocal === "2") {
          const result = response.data.data.result;
          setRoleForUser(result.id);
        } else {
          const result = response.data.data.result;
          if (result?.code === "ER_DUP_ENTRY") {
            setErrorMess("Tên đăng nhập đã tồn tại");
          } else {
            localStorage.setItem("indexTabAcc", indexTabLocal);
            navigate("/account");
          }
        }
      }
    });
  };

  const setRoleForUser = async (userId) => {
    const params = {
      role: form.getFieldValue("role"),
    };

    await API.patch(`${USER}/${userId}/role`, params).then((response) => {
      if (response.data.data.code === 20004) {
        localStorage.setItem("indexTabAcc", indexTabLocal);
        navigate("/account");
      }
    });
  };

  const backScreen = () => {
    localStorage.setItem("indexTabAcc", indexTabLocal);
    navigate("/account");
  };

  const renderFormByAdmin = () => {
    return (
      <Form
        className="form-create"
        form={form}
        layout="inline"
        autoComplete="off"
        onFinish={onCreate}
      >
        <div className="col-8">
          <div
            className="col-12"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-3">
              {TEXT_DEF.text0002} <span style={{ color: "red" }}>*</span>
            </div>
            <div className="col-9">
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: TEXT_DEF.text0004,
                  },
                ]}
                style={{ marginRight: 0 }}
              >
                <Input maxLength={255} />
              </Form.Item>
            </div>
          </div>

          <div style={{ marginBottom: 15 }} />

          <div
            className="col-12"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-3">
              {TEXT_DEF.text0003} <span style={{ color: "red" }}>*</span>
            </div>
            <div className="col-9">
              <Tooltip title="Mật khẩu có độ dài từ 8-32 ký tự. Bắt buộc có ký tự hoa, ký tự thường, số, ký tự đặc biệt (!@#$%&*)">
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: TEXT_DEF.text0004,
                    },
                    {
                      validator: (_, value) => {
                        if (
                          /^((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*]).{8,32})/.test(
                            value
                          )
                        ) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject("Some message here");
                        }
                      },
                      message: TEXT_DEF.text0003 + " " + TEXT_DEF.text0005,
                    },
                  ]}
                  className="col-12"
                >
                  <Input.Password min={8} maxLength={32} />
                </Form.Item>
              </Tooltip>
            </div>
          </div>

          <div style={{ marginBottom: 15 }} />

          <div
            className="col-12"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-3">
              {TEXT_DEF.text0038} <span style={{ color: "red" }}>*</span>
            </div>
            <div className="col-9">
              <Form.Item
                name="role"
                rules={[
                  {
                    required: true,
                    message: TEXT_DEF.text0004,
                  },
                ]}
                style={{ marginRight: 0 }}
              >
                <Select>
                  {listRole.map((item) => (
                    <Option key={item.id} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>

          <div style={{ marginBottom: 30 }} />

          <Form.Item shouldUpdate style={{ width: "100%" }}>
            {() => (
              <div className="lg-btn">
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !form.isFieldsTouched(true) ||
                    !!form
                      .getFieldsError()
                      .filter(({ errors }) => errors.length).length
                  }
                >
                  {TEXT_DEF.text0026}
                </Button>
              </div>
            )}
          </Form.Item>
        </div>
      </Form>
    );
  };

  const renderFormByUser = () => {
    return (
      <Form
        className="form-create"
        form={form}
        layout="inline"
        autoComplete="off"
        onFinish={onCreate}
      >
        <div className="col-8">
          <div
            className="col-12"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-3">
              {TEXT_DEF.text0002} <span style={{ color: "red" }}>*</span>
            </div>
            <div className="col-9">
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: TEXT_DEF.text0004,
                  },
                ]}
                style={{ marginRight: 0 }}
              >
                <Input maxLength={255} onChange={() => setErrorMess()} />
              </Form.Item>
            </div>
          </div>
          <div
            className="col-12"
            style={{ display: "flex", alignItems: "center", color: "red" }}
          >
            <div className="col-3"></div>
            <div className="col-9">{errorMess}</div>
          </div>
          <div style={{ marginBottom: 30 }} />

          <Form.Item shouldUpdate style={{ width: "100%" }}>
            {() => (
              <div className="lg-btn">
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !form.isFieldsTouched(true) ||
                    !!form
                      .getFieldsError()
                      .filter(({ errors }) => errors.length).length
                  }
                >
                  {TEXT_DEF.text0026}
                </Button>
              </div>
            )}
          </Form.Item>
        </div>
      </Form>
    );
  };

  return (
    <div className="col-12">
      <Button
        type="default"
        htmlType="button"
        style={{ marginBottom: 30 }}
        onClick={backScreen}
      >
        {TEXT_DEF.text0034}
      </Button>
      {indexTabLocal === "1" ? renderFormByUser() : renderFormByAdmin()}
    </div>
  );
};

export default CreateAccount;
